import actions from './actions'

const initialState = {
  utilities: [],
  exportUtilities: [],
  buildingDetails: {},
  electricityUnit: '',
  loading: false,
  histLoading: false,
  amount: '',
  vendingDebt: '',
  paymentMethod: '',
  modal: false,
  convinienceFee: null,
  virtualAccount: [],
}

export default function utilitiesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
